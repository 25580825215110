<template>
  <div class="container">
    <div class="home" style="text-align: center">
      <router-link to="/login" class="btn btn-black login-btn mt-5 w-100"
        >Login</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>
<style scoped>
.home .login-btn {
  max-width: 180px;
}
</style>
